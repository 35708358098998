import Vue from "vue";
import Vuex from "vuex";
import requestItemsOverview from "@/store/modules/requestItemsOverview";
import cratesOverview from "@/store/modules/cratesOverview";
import plansOverview from "@/store/modules/plansOverview";
import locationsList from "@/store/modules/locationsList";
import dialogOverview from "@/store/modules/dialogOverview";
import reports from "@/store/modules/reports";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    requestItemsOverview,
    cratesOverview,
    plansOverview,
    locationsList,
    reports,
    dialogOverview
  },
  strict: debug,
  plugins: debug ? [Vuex.createLogger()] : [],
  getters: {}
});

export default store;
