import { tableState, tableMutations, tableActions, tableGetters } from "@tt/vue-components";
import skuFilter from "@/store/modules/requestItemsOverview/filter/skuFilter";
import orderNoFilter from "@/store/modules/requestItemsOverview/filter/orderNoFilter";
import statusFilter from "@/store/modules/requestItemsOverview/filter/statusFilter";
import locationsFilter from "@/store/modules/requestItemsOverview/filter/locationsFilter";
import tickets from "@/store/modules/requestItemsOverview/tickets";
import allocationService from "@/services/AllocationService";

const requestItemsOverview = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    filters: [],
    defaultFilters: [],
    sorting: { sortBy: ["request.createdDt"], sortDesc: [true] },
    showDetails: false,
    requestItem: null
  },
  modules: {
    skuFilter,
    orderNoFilter,
    statusFilter,
    locationsFilter,
    tickets
  },
  mutations: {
    ...tableMutations,
    SET_SHOW_DETAILS(state, showDetails) {
      state.showDetails = showDetails;
    },
    SET_REQUEST_ITEM(state, requestItem) {
      state.requestItem = requestItem;
    }
  },
  actions: {
    ...tableActions,
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = { pagination: context.state.pagination, sorting: context.state.sorting, search: context.state.search };

        allocationService.requestItems.list(params)
          .then(json => {
            if (json) {
              context.commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
    setShowDetails(context, showDetails) {
      context.commit("SET_SHOW_DETAILS", showDetails);
    },
    setRequestItem(context, requestItem) {
      context.commit("SET_REQUEST_ITEM", requestItem);
      context.dispatch("tickets/fetchItems", true);
    },
    search(context) {
      let params = [];

      if (context.state.defaultFilters.length || context.state.filters.length) {
        let filters = [...context.state.defaultFilters, ...context.state.filters];

        filters.forEach(filter => {
          let filterParams = context.getters[filter.name + "/toParams"];
          if (filterParams !== undefined && filterParams.length > 0) {
            for (let i = 0; i < filterParams.length; i++) {
              params.push(filterParams[i]);
            }
          }
        });
      }
      if (params.length) {
        context.commit("SET_SEARCH", params.filter(param => { return param.value !== undefined && param.value !== null && param.value !== "" && param.value !== []}));
      }
      context.dispatch("fetchItems", true);
    },
    edit(context, requestItem) {
      allocationService.requestItems.update(requestItem['id'], requestItem).then(() => {
        context.commit("SET_REQUEST_ITEM", requestItem);
      });
    }
  },
  getters: {
    ...tableGetters
  }
};

export default requestItemsOverview;
