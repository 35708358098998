import createClient from "@tt/vue-components/src/services/apiClient";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService";

const apiClient = createClient(EnvProvider.value("INVENTORY_API_URL"));

const InventoryService = {
  skus: {
    ...baseApiService(apiClient, 'skus')
  }
}

export default InventoryService;
