import allocationService from "@/services/AllocationService";
import {tableActions, tableMutations, tableState} from "@tt/vue-components";

const reports = {
    requestController: null,
    namespaced: true,
    state: {
        ...tableState,
        history: [],
        loading: false
    },
    mutations: {
        ...tableMutations,
        SET_LOADING(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        ...tableActions,
        fetchItems({ commit, state }, ticket) {
            commit("SET_LOADING", true);
            const params = {
                pagination: { itemsPage: 1, itemsPerPage: 9999 },
                sorting: { sortBy: ["priority"], sortDesc: [true] },
                search: state.search
            };
            if (ticket) {
                if (this.requestController) {
                    this.requestController.abort();
                }
                this.requestController = new AbortController();
                allocationService.tickets.getSubResource(ticket.id, `ticket_history`, params, { signal: this.requestController.signal })
                    .then(json => {
                        if (json) {
                            commit("SET_ITEMS", json);
                        }
                        commit("SET_LOADING", false);
                    })
                    .catch(err => {
                        commit("SET_LOADING", false);
                        console.log(err);
                    });
            } else {
                commit("SET_ITEMS", []);
                commit("SET_LOADING", false);
            }
        }
    },
};

export default reports;
