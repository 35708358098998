
import allocationService from "@/services/AllocationService";
import reportFilter from "@/store/modules/reports/filters/reportFilter";
import timeframeFilter from "@/store/modules/reports/filters/timeframeFilter";
import {tableActions, tableMutations, tableState} from "@tt/vue-components";

const reports = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    generatedReport: null,
    filters: [],
    loading: false
  },
  modules: {
    reportFilter,
    timeframeFilter
  },
  mutations: {
    ...tableMutations,
    SET_GENERATED_REPORT(state, report) {
      state.generatedReport = report;
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    ...tableActions,
    fetchGeneratedReport({ commit, state, rootGetters }) {
      commit("SET_LOADING", true);
      const params = { pagination: {itemsPage: 1, itemsPerPage: 9999}, sorting: state.sorting, search: state.search };
      const report = rootGetters["reports/reportFilter/data"]
      const timeframe = rootGetters["reports/timeframeFilter/data"]
      if (report && timeframe) {

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        allocationService.reports.getSubResource(report, `time_frames/${timeframe}/generated`, params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_GENERATED_REPORT", json);
            }
            commit("SET_LOADING", false);
          })
          .catch(err => {
            commit("SET_LOADING", false);
            console.log(err);
          });
      } else {
        commit("SET_GENERATED_REPORT", null);
        commit("SET_LOADING", false);
      }
    },
    regenerateReport({rootGetters}) {
      const timeframe = rootGetters["reports/timeframeFilter/data"]
      const report = rootGetters["reports/reportFilter/data"]
      if (timeframe) {
        const body = {
          reportId: report,
          timeFrameId: timeframe
        }
        allocationService.reports.regenerateReport(body)
      }
    }
  },
};

export default reports;
